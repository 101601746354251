const scrollButton = document.getElementById('scroll-button');
const scrollTarget = document.querySelector('.vita-scroll-text');
const scrollContainer = document.querySelector('.scroll-arrow-container');

if (scrollButton) {
  scrollButton.addEventListener('click', () => {
    if (scrollTarget) {
      scrollTarget.scrollBy(0, 50);
    }
  });
}

setTimeout(() => {
  scrollContainer.classList.add('stop-animation');
}, 8000);