// lightbox 

const modal = document.querySelector('.modal-container');
const content = document.createElement('div');
const containerContent = document.querySelectorAll('.content-container')

// adding image tag inside modal container
const contentImage = document.createElement('img');

    content.appendChild(contentImage);

// looping into images to add click event
containerContent.forEach(e => {

  if (e.classList.contains('zoomable')) {

    e.querySelector('.slides').classList.add('slides-image-hover');

    e.addEventListener('click', (checker) => {

      if (checker.target.tagName === 'IMG') {
      contentImage.src = checker.target.src;
      
      modal.appendChild(content);
      modal.classList.add('modal-open');
      }
    })
  }
});

// wont let the user close the window if he clicked on the image
if (modal) {
  modal.addEventListener('click', (e)=> {
    if(e.target.tagName !== 'IMG') {
      modal.classList.remove('modal-open')
    }
  });
} else { 
  // do nothing
}

